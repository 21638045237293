
interface DetalleDevolucions {
  ubicacionEventoId: number;
}

interface Id {
  id: number;
}

interface IdNulleable {
  id: number | null;
}
export class Devolucion {
  private comentario: string;
  private fecha: number;
  private importe: number;
  private detalleDevolucions: DetalleDevolucions[];
  private reservaId: Id;
  private turnoId: IdNulleable;
  private vendedorId: Id;
  private usuariopsId: Id;


  constructor(
    comentario: string,
    fecha: number,
    importe: number,
    detalleDevolucions: DetalleDevolucions[],
    reservaId: number,
    turnoId: number | null,
    vendedorId: number,
    usuariopsId: number
  ) {
    this.comentario = comentario;
    this.fecha = fecha;
    this.importe = importe;
    this.detalleDevolucions = detalleDevolucions;
    this.reservaId = { id: reservaId };
    this.turnoId = { id: turnoId };
    this.vendedorId = { id: vendedorId };
    this.usuariopsId = { id: usuariopsId };

  }
}
