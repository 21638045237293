import { useLocation } from 'react-router-dom';

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function getTokenForUrl() {
  const token = localStorage.getItem('token');
  return '?token=' + token;
}

export const toastSwalError = (swalInstance: any, message: string, position = 'bottom') => {
  return swalInstance.fire({
    toast: true,
    position: position,
    icon: 'error',
    titleText: `${message}`,
    background: '#222',
    customClass: {
      title: 'color-white'
    },
    confirmButtonColor: '#EE4D56'
  });
};

export const handleFetchSuccess = (message: string, swalInstance: any) => {
  swalInstance.fire({
    toast: true,
    position: 'bottom',
    icon: 'success',
    titleText: `${message}`,
    background: '#F2F2F2',
    confirmButtonColor: '#4caf50',
    timerProgressBar: true,
    timer: 3000
  });
};

export const dispatchSwalAlert = (swalInstance: any, mensaje: string) => {
  return swalInstance.fire({
    text: mensaje,
    confirmButtonText: 'Aceptar',
    showCancelButton: true,
    icon: 'warning'
  });
};

export const handleFetchError = (swal: any, errorResponse: string) => {
  let error = errorResponse;
  if (typeof error !== 'string') error = '';
  return toastSwalError(swal, errorResponse);
};
