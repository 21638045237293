import Swal from 'sweetalert2';

export default function passwordDialog() {
  const checkPassword = (pass: string) => {
    if (pass === process.env.REACT_APP_COFFE_U) {
      return true;
    }
    return false;
  };
  return Swal.fire({
    title: 'Ingrese la contraseña',
    input: 'password',
    confirmButtonText: 'ACEPTAR',
    showCancelButton: true,
    cancelButtonText: 'CANCELAR',
    cancelButtonColor: '#ef4565',
    inputPlaceholder: 'Contraseña',
    inputAttributes: {
      maxlength: '10',
      autocapitalize: 'off',
      autocorrect: 'off'
    },
    customClass: {
      title: 'swal__password--title',
      popup: 'swal__password--popup',
      input: 'swal__password--input',
      confirmButton: 'swal__password--confirm-button',
      cancelButton: 'swal__password--cancel-button'
    },
    inputValidator: value => {
      if (!value) {
        return 'Debe ingresar un valor!';
      }
      if (!checkPassword(value)) {
        return 'Contraseña incorrecta';
      }
      return null;
    }
  });
}
