import { Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import React, { useEffect, useState } from 'react';
import { IAnulacion } from '../../../../models/anulaciones.model';
import { IAnulacionLista, ITablaAnulaciones } from '../../../../models/page/lista-anulacion.model';
import FilaExpandible from '../filas-expandibles/filas-expandibles.component';

const useStyles = makeStyles({
  table: {
    minWidth: 700
  }
});

function setIsDown(rows: any, row: IAnulacionLista, setRows: React.Dispatch<any>) {
  const indice = rows.indexOf(row);
  setRows(
    rows.map((el: IAnulacionLista, idx: number) => {
      if (idx === indice) el.isDown = !el.isDown;
      return el;
    })
  );
}

const TablaAnulaciones = ({ devoluciones }: ITablaAnulaciones) => {
  const classes = useStyles();
  const [rows, setRows] = useState();

  // agregamos propiedades para expandir
  useEffect(() => {
    setRows(
      devoluciones.map((devolucion: IAnulacion) => ({
        ...devolucion,
        isDown: false,
        elementToRender: FilaExpandible(devolucion.detalleDevolucions)
      }))
    );
  }, [devoluciones]);

  const handleChange = (row: IAnulacionLista) => {
    setIsDown(rows, row, setRows);
  };

  const formatDate = (date: number) => {
    const _date = new Date(date);
    return `${_date.getDate()}/${_date.getMonth() + 1}/${_date.getFullYear()}`;
  };

  return (
    <Paper>
      <Table className={classes.table} aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={4}>
              Anulaciones
            </TableCell>
            <TableCell align="right">Accion</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell align="right">Fecha</TableCell>
            <TableCell align="right">Importe</TableCell>
            <TableCell align="right">Comentario</TableCell>
            <TableCell align="right">Detalle</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows &&
            rows.map((row: IAnulacionLista) => (
              <>
                <TableRow key={row.comentario + row.fecha}>
                  <TableCell>{row.importe}</TableCell>
                  <TableCell align="right">{formatDate(row.fecha)}</TableCell>
                  <TableCell align="right">${row.importe}</TableCell>
                  <TableCell align="right">{row.comentario}</TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      color={row.isDown ? 'secondary' : 'primary'}
                      onClick={() => handleChange(row)}
                    >
                      Detalles
                      {row.isDown === true ? (
                        <ArrowDropUpIcon style={{ color: 'blue' }} />
                      ) : (
                        <ArrowDropDownIcon style={{ color: 'red' }} />
                      )}
                    </Button>
                  </TableCell>
                </TableRow>
                {row.isDown && row.elementToRender()}
              </>
            ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default TablaAnulaciones;
