
interface IImpresion {
    title: string;
    content: string[];
}

const impresion = ({ content, title }: IImpresion) => {

    const contenido = content.map(
        el => `<span style="font-size: 16px">${el}</span><br>`
    ).join('').replace(',', ' ');

    const paraImprimir = (`<div>
        <h3>${title}</h3>
        ${contenido}
        </div>`)

    let instanceWindow = window.open('', '_blank', 'width=700,height=450');
    if (instanceWindow) {
        instanceWindow!.document.write(paraImprimir);
        instanceWindow.document.close()
        instanceWindow.focus()
        instanceWindow.print()
    }

}

export default impresion
