import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import MenuAppBar from './components/menu-app-bar/menu-app-bar.component';
import { UserContext } from './context/user.service';
import { UserLogged } from './models/user.model';
import Home from './views/home/home.page';
import ListaAnulaciones from './views/lista-anulaciones/lista-anulaciones.page';
import NotFoundPage from './views/not-found/not-found.page';
import SignInAndSignUp from './views/sign-in-and-sign-up/sign-in-and-sign-up.component';

const App: React.FC = () => {
  const userContext = useContext(UserContext);

  const hasUser = userContext.user && (userContext.user as UserLogged).token;

  return (
    <>
      {hasUser ? (
        <MenuAppBar>
          <Switch>
            <Route exact path="/lista-anulaciones" component={ListaAnulaciones} />
            <Route path="/:turnoId" component={Home} />
            <Route path="/" component={Home} />
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </MenuAppBar>
      ) : (
          <SignInAndSignUp />
        )}
    </>
  );
};

export default App;
