type environment = 'produccion' | 'test' | 'localhost';

const environment: environment = process.env.REACT_APP_ENV as environment;

function setEnviroment(_environment: environment) {
    switch (_environment) {
        case 'localhost':
            return 'http://localhost:9090/';
        case 'produccion':
            return 'https://services.paseshow.com.ar/permissions/';
        case 'test':
            return 'https://test.paseshow.com.ar/permissions/';
        default:
            return 'https://test.paseshow.com.ar/permissions/';
    }
}

const PermissionsUrl = setEnviroment(environment);

export default PermissionsUrl;