type environment = 'produccion' | 'test' | 'localhost';

const environment: environment = process.env.REACT_APP_ENV as environment;

function setEnviroment(_environment: environment) {
  switch (_environment) {
    case 'localhost':
      return 'http://localhost:8080';
    case 'produccion':
      return 'https://api.paseshow.com.ar/';
    case 'test':
      return 'https://www.paseshow.com.ar/test';
    default:
      return 'https://www.paseshow.com.ar/test';
  }
}

const ApiUrl = setEnviroment(environment);

export default ApiUrl;
