import Swal from "sweetalert2";
import { IReserva, IUbicacionEventoes } from "../../../models/reserva.model";
import { toastSwalError } from "../../../shared/utils/utils";

interface IHomeStateReducer {
    open: boolean;
    reserva: IReserva;
    disabledConfirm: boolean;
    ubicaciones: IUbicacionEventoes[];
    codigo: string;
    turnoParams: string;
    showError: any;
  }
  interface IHomeActionReducer {
    payload?: any;
    type: 'loaded' | 'anularParcial' | 'limpiar' | 'error' | 'setCodigo' | 'setTurnoParam';
  }
  
  const showErrorMessage = (error: string) => {
    toastSwalError(Swal, error, 'rigth');
  };
  
  const spliceUbicaciones = (
    currentUbicaciones: IUbicacionEventoes[],
    paraAnular: IUbicacionEventoes[]
    ) => {
      const newUbicaciones = [...currentUbicaciones];

      for (let anulacion of paraAnular) {
      newUbicaciones!.splice(newUbicaciones!.indexOf(anulacion), 1);
    }
    return newUbicaciones;
  };
  
  const homeReducer = (state: IHomeStateReducer, action: IHomeActionReducer) => {
    switch (action.type) {
      case 'setTurnoParam':
        return {
          ...state,
          turnoParams: action.payload
        };
      case 'setCodigo':
        return {
          ...state,
          codigo: action.payload
        };
      case 'loaded':
        return {
          ...state,
          reserva: action.payload,
          ubicaciones: action.payload.ubicacionEventoes,
          open: true,
          disabledConfirm: true
        };
      case 'anularParcial':
        return {
          ...state,
          ubicaciones: spliceUbicaciones(state.ubicaciones, action.payload)
        };
      case 'limpiar':
        return {
          ...state,
          reserva: [],
          ubicaciones: [],
          codigo: '',
          open: false,
          disabledConfirm: false
        };
      case 'error':
        return {
          ...state,
          showError: showErrorMessage(action.payload)
        };
    }
  };
  

  export default homeReducer;