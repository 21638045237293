import React, { useState } from 'react';
import Swal from 'sweetalert2';
import CodigoReservaCard from '../../components/codigo-reserva-card/codigo-reserva-card.component';
import { URI_LISTAR } from '../../shared/api-uris/uri-listar';
import { getTokenForUrl, toastSwalError } from '../../shared/utils/utils';
import TablaAnulaciones from './components/tabla-anulaciones/tabla-anulaciones';
import listaStyles from './lista-anulaciones.styles';

const useStyles = listaStyles;

const ListaAnulaciones = () => {
  const classes = useStyles();
  const [anulaciones, setAnulaciones] = useState([]);
  // prueba 2353

  const searchList = async (turnoId: string) => {
    const url = getUrlListaDevoluciones(turnoId);
    try {
      const response = await fetch(url.href, { method: 'GET' });
      if (!response.ok) handleResponseFalse(response);

      const anulaciones = await response.json();
      if (anulaciones.length === 0) toastSwalError(Swal, 'No hay anulaciones para mostrar');
      setAnulaciones(anulaciones);
    } catch (error) {
      toastSwalError(Swal, `Error: compruebe la conexion o el numero de turno id`);
    }
  };

  const getUrlListaDevoluciones = (turno: string) => {
    const url = new URL(URI_LISTAR + getTokenForUrl());
    url.searchParams.append('find', 'ByTurnoId');
    url.searchParams.append('turnoId', turno);
    return url;
  };

  const handleResponseFalse = (response: Response) => {
    toastSwalError(Swal, `Error : ${response.statusText}`);
    if (response.status === 401) {
      localStorage.clear();
      window.location.replace('/');
    }
  };

  return (
    <section>
      <div className={classes.titleHome}>
        <h2>Listar anulaciones</h2>
        <div className={classes.lineTitle} />
      </div>
      <CodigoReservaCard
        title="Ingresar turno id"
        placeHolder="turno id"
        disabledButton={false}
        handleChange={searchList}
      />
      {anulaciones.length > 0 && <TablaAnulaciones devoluciones={anulaciones} />}
    </section>
  );
};

export default ListaAnulaciones;
