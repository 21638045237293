import { Divider, List, ListItem, Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { IReserva } from '../../../../models/reserva.model';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper
    },
    titleList: {
      fontSize: '20px',
      fontWeight: 'bold'
    },
    listItem: {
      margin: 0
    }
  })
);

interface ITabla {
  row: IReserva;
}


const Tabla = function({ row }: ITabla) {
  const classes = useStyles();

  const transformTimeToDate = (time: number | null) => {
    if (!time) return '**vacio**';
    const day = new Date(time).getDate();
    const month = new Date(time).getMonth();
    const year = new Date(time).getFullYear();
    return `${day}/${month + 1}/${year}`;
  };
  
  return (
    <Paper className={classes.root}>
      <List aria-label="secondary mailbox folders">
        <ListItem>
          <p className={classes.listItem}>
            <span className={classes.titleList}>Cliente id : </span>
            {row.clienteId.id}
          </p>
        </ListItem>
        <Divider />

        <ListItem>
          <p className={classes.listItem}>
            <span className={classes.titleList}>Cliente dni : </span>
            {row.clienteId.dni}
          </p>
        </ListItem>
        <Divider />

        <ListItem>
          <p className={classes.listItem}>
            <span className={classes.titleList}>Cliente nombre : </span>
            {row.clienteId.nombre}
          </p>
        </ListItem>
        <Divider />

        <ListItem>
          <p className={classes.listItem}>
            <span className={classes.titleList}>Fecha facturacion : </span>
            {transformTimeToDate(row.fechaFacturacion)}
          </p>
        </ListItem>
        <Divider />

        <ListItem>
          <p className={classes.listItem}>
            <span className={classes.titleList}>Comprobante : </span>
            {row.tipo}
          </p>
        </ListItem>
        <Divider />

        <ListItem>
          <p className={classes.listItem}>
            {' '}
            <span className={classes.titleList}>Importe total : </span>
            {row.importeTotal}
          </p>
        </ListItem>
        <Divider />

        <ListItem>
          <p className={classes.listItem}>
            <span className={classes.titleList}>Evento : </span>
            {row.ubicacionEventoes[0].sectorEventoId.eventoId.nombre}
          </p>
        </ListItem>
        <Divider />

        <ListItem>
          <p className={classes.listItem}>
            {' '}
            <span className={classes.titleList}>Escenario : </span>
            {row.ubicacionEventoes[0].sectorEventoId.eventoId.escenarioId.nombre}
          </p>
        </ListItem>
        <Divider />

        <ListItem>
          <p className={classes.listItem}>
            <span className={classes.titleList}>Vendedor id : </span>
            {row.vendedorId.id}
          </p>
        </ListItem>
        <Divider />

        <ListItem>
          <p className={classes.listItem}>
            <span className={classes.titleList}>Vendedor dni : </span>
            {row.vendedorId.dni}
          </p>
        </ListItem>
        <Divider />

        <ListItem>
          <p className={classes.listItem}>
            <span className={classes.titleList}>Vendedor nombre : </span>
            {row.vendedorId.nombre}
          </p>
        </ListItem>
        <Divider />

        <ListItem>
          <p className={classes.listItem}>
            <span className={classes.titleList}>Sector id : </span>
            {row.ubicacionEventoes[0].sectorEventoId.sectorId.id}
          </p>
        </ListItem>
        <Divider />

        <ListItem>
          <p className={classes.listItem}>
            <span className={classes.titleList}>Sector nombre : </span>
            {row.ubicacionEventoes[0].sectorEventoId.sectorId.nombre}
          </p>
        </ListItem>
        <Divider />

        <ListItem>
          <p className={classes.listItem}>
            <span className={classes.titleList}>Sucursal id : </span>
            {row.turnoId ? row.turnoId?.cajaId?.puntoVentaId?.sucursalId?.id : 'nulo'}
          </p>
        </ListItem>
        <Divider />

        <ListItem>
          <p className={classes.listItem}>
            <span className={classes.titleList}>Turno id : </span>
            {row.turnoId ? row.turnoId?.id : 'nulo'}
            
          </p>
        </ListItem>
        <Divider />

        <ListItem>
          <p className={classes.listItem}>
            <span className={classes.titleList}>Sucursal nombre : </span>
            {row.turnoId ? row.turnoId?.cajaId?.puntoVentaId?.sucursalId?.nombre : 'nulo'}
          </p>
        </ListItem>
        
        <Divider />

        <ListItem>
          <p className={classes.listItem}>
            <span className={classes.titleList}>Función : </span>
            {row.ubicacionEventoes[0].descuentoSectorId.sectorEventoId.descripcion}
          </p>
        </ListItem>

        </List>
    </Paper>
  );
};

export default Tabla;
