import { createStyles, makeStyles, Theme } from '@material-ui/core';

const ubicacionesListStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      maxHeight: '257px',
      overflowY: 'scroll',
    
    },
    commentButton: {
      fontSize: '18px'
    },
    ubicacionListId: {
      color: '#3F51B5',
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },
    ubicacionesListEtiquetas: {
      color: 'green',
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },
    ubicacionesListDescuento: {
      color: 'orange',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    ubicacionesListIngreso: {
      color: 'darkmagenta',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    selectAllList: {
      paddingLeft: '16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      
    },
    // lineaHorizontal:{
    //   height: '10px',
    //   width: '50%',
    //   backgroundColor: 'black'


    // },
  })
);

export default ubicacionesListStyles;
