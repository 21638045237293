import Button from '@material-ui/core/Button';
import React, { ChangeEvent, FormEvent, useContext, useState } from 'react';
import Swal from 'sweetalert2';
import { UserContext } from '../../context/user.service';
import { URI_AUTH } from '../../shared/api-uris/uri-auth';
import { handleFetchError, toastSwalError } from '../../shared/utils/utils';
import FormInput from '../form-input/form-input.component';
import './signin.styles.scss';

const SignIn = () => {
  const [state, setState] = useState({ password: '', username: '' });
  const contextUser = useContext(UserContext);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const { username, password } = state;
    try {
      const params = new URLSearchParams();
      params.append('username', username);
      params.append('password', password);
      const userFetchResponse = await fetch(URI_AUTH, {
        method: 'POST',
        body: params
      });
      if (userFetchResponse.ok) {
        const userResponse = await userFetchResponse.json();
        setLocalStorageUser(userResponse.token);
        contextUser.updateValue(userResponse);
      } else {
        toastSwalError(Swal, 'Contraseña o dni incorrectos');
      }
    } catch (error) {
      handleFetchError(Swal, error);
    }
  };

  const setLocalStorageUser = (user: string) => {
    try {
      localStorage.setItem('token', user);
      contextUser.updateValue({ token: user });
    } catch {
      console.error('Error');
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setState(state => ({ ...state, [name]: value }));
  };

  return (
    <div className="sign-in">
      <h2>Log in</h2>
      <span>Ingrese con su username y contraseña</span>

      <form onSubmit={handleSubmit}>
        <FormInput
          name="username"
          type="username"
          handleChange={handleChange}
          required
          label="Dni"
          value={state.username}
        />
        <FormInput
          name="password"
          type="password"
          handleChange={handleChange}
          required
          label="Contraseña"
          value={state.password}
        />
        <div className="buttons">
          <Button variant="contained" type="submit" color="primary">
            Ingresar
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SignIn;
