import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { IDetalleDevolucion } from '../../../../models/anulaciones.model';

const FilaExpandible = (detalles: IDetalleDevolucion[]) => (): any => {
  const styleSecondaryRow = { backgroundColor: '#dedef096', border: '1px solid grey' };
  const stylePrimaryRow = { backgroundColor: '#64b2cd', border: '1px solid grey' };
  return detalles.map(detalle => (
    <>
      <TableRow>
        <TableCell rowSpan={5} />
        <TableCell style={stylePrimaryRow} colSpan={3}>
          Descuento Sector Id
        </TableCell>
        <TableCell style={stylePrimaryRow} align="right">
          {detalle.descuentoSectorId}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={styleSecondaryRow} colSpan={3}>
          Id
        </TableCell>
        <TableCell style={styleSecondaryRow} align="right">
          {detalle.id}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={styleSecondaryRow} colSpan={3}>
          Precio
        </TableCell>
        <TableCell style={styleSecondaryRow} align="right">
          {detalle.precio}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={styleSecondaryRow} colSpan={3}>
          Precio Neto
        </TableCell>
        <TableCell style={styleSecondaryRow} align="right">
          {detalle.precioNeto}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={styleSecondaryRow} colSpan={3}>
          Ubicacion Evento id
        </TableCell>
        <TableCell style={styleSecondaryRow} align="right">
          {detalle.ubicacionEventoId}
        </TableCell>
      </TableRow>
    </>
  ));
};

export default FilaExpandible;
