import React from 'react';
import './form-input.styles.scss';

interface IFormInput {
    handleChange: (prop : React.ChangeEvent<HTMLInputElement>) => any;
    label: string;
    value : string;
    [key: string] : any;
}

const FormInput = ({ handleChange, label, ...otherProps }: IFormInput) => (
    <div className='group'>
        <input className='form-input' onChange={handleChange} {...otherProps} required/>
        {
            label ?
                (<label className={`${otherProps.value.length ? 'shrink' : ''} form-input-label`}>
                    {label}
                </label>)
                :
                null
        }
    </div>
);

export default FormInput;