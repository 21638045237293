import { Button, Card, CardActions, CardContent } from '@material-ui/core';
import React, { ChangeEvent, useState } from 'react';
import Swal from 'sweetalert2';
import FormInput from '../form-input/form-input.component';
import { CardReservaStyles } from './codigo-reserva.styles';

const useStyles = CardReservaStyles;

interface ICodigoReservaCard {
  handleChange: (event: string) => void;
  disabledButton: boolean;
  title: string;
  placeHolder: string;
} 

const CodigoReservaCard = ({ handleChange, disabledButton, title, placeHolder }: ICodigoReservaCard) => {
  const classes = useStyles();
  const [ codigo, setCodigo ] = useState('');

  

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setCodigo(value);
  };

  const handleSubmit = (value: string) => {
    if (!value) {
      return Swal.fire({
        text: 'Debe ingresar un valor',
        confirmButtonColor: 'red'
      });
    }
    setCodigo('');
    handleChange(value);
  };

  return (
    <section className={classes.CodigoReservaContainer}>
      <Card className={classes.CodigoReservaCard}>
  <h3>{title}</h3>
        <CardContent>
          <div className={classes.CodigoReservaInput}>
            <FormInput
              handleChange={handleChangeInput}
              label={placeHolder}
              name="reservaId"
              type="text"
              value={codigo}
              disabled={disabledButton}
              required
              onKeyUp={(e: KeyboardEvent) => {
                if (e.key === 'Enter') handleSubmit(codigo);
              }}
              />
            <Button
              className={classes.CodigoReservaButton}
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => handleSubmit(codigo)}
              type="submit"
              disabled={disabledButton}
              onKeyUp={() => handleSubmit(codigo)}
              >
              Confirmar
            </Button>
          </div>
        </CardContent>
        <CardActions className={classes.CodigoReservaCardActions} />
      </Card>
              
    </section>
  );
};

export default CodigoReservaCard;
