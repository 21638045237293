import { makeStyles } from "@material-ui/core";


const listaStyles = makeStyles({
  homePage: {
    display: "flex",
    flexDirection: 'row',
    width: '75vw',
    marginBottom: '34px',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '316px'
  },
  etiquetas: {
    width: '60%',
    right: 0
  },
  codigoContainer: {
  },
  etiquetasContainer: {
    height: '100%'
  },
  lineTitle:{
    border: 0, 
    height: '1px',
     textAlign: 'center', 
     backgroundImage: 'linear-gradient(left, #801336, #801336, #fff)',
     width:' 70%',
    alignSelf: 'center',
    marginLeft: '49px'

  },
  titleHome: {
    display: 'flex',
    flexDirection:'row'
  }

});

export default listaStyles;