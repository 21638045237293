import { makeStyles } from "@material-ui/core";

export const CardReservaStyles = makeStyles({
  CodigoReservaContainer: {
    display: "flex",
    alignItems: "center",
    margin: '10px',
    flexDirection: 'column',
    width: '348px',
    height: '100%'
  },
  CodigoReservaCard: {
    width: '100%',
    position: "relative",
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    padding: '13px'
  },
  CodigoReservaCardActions: {
    position: "absolute",
    bottom: '1rem',
    width: "100%"
  },
  CodigoReservaButton: {
    margin: "0 auto",
    fontSize: "12px"
  },
  CodigoReservaInput: {
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '300px',
    padding: "0"
  }
});
