import React, { FunctionComponent, useEffect, useState } from 'react';
import { UserLogged } from '../models/user.model';

interface IUser {
  user: string | {};
  updateValue: (user: any) => void;
}

export const UserContext = React.createContext<IUser>({ user: {}, updateValue: () => {} });

const UserService: FunctionComponent = ({ children }) => {
  const accessToken = localStorage.getItem('token');
  const [user, setUser] = useState({});

  useEffect(() => {
    if (accessToken) {
      setUser({
        token: accessToken
      });
    }
  }, [accessToken]);

  const changeUser = (user: UserLogged) => {
    if (!user) {
      localStorage.clear();
      setUser(user);
    }
    setUser(user);
    if (user) {
      localStorage.setItem('token', user.token);
    }
  };

  return (
    <UserContext.Provider value={{ user, updateValue: changeUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserService;
