import React from 'react';
import loginBG from '../../assets/login-min.png';
import SignIn from '../../components/signin/signin.component';
import './sign-in-and-sign-up.styles.scss';

const SignInAndSignUp = () => (
  <div className="login">
    <div className="sign-in-and-sign-up">
      <SignIn />
    </div>
    <img src={loginBG} width="440" height="400" alt="Log in" />
  </div>
);
export default SignInAndSignUp;
